@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'ABCDiatype';
    font-weight: 400;
    font-style: italic;
    src: url('../shared/assets/fonts/ABCDiatype-RegularItalic.woff') format('woff'),
    url('../shared/assets/fonts/ABCDiatype-RegularItalic.woff') format('woff2'),
    url('../shared/assets/fonts/ABCDiatype-RegularItalic.otf') format('opentype');
}

@font-face {
    font-family: 'ABCDiatype';
    font-weight: 400;
    src: url('../shared/assets/fonts/ABCDiatype-Regular.woff') format('woff'),
    url('../shared/assets/fonts/ABCDiatype-Regular.woff2') format('woff2'),
    url('../shared/assets/fonts/ABCDiatype-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'ABCDiatype';
    font-weight: 600;
    src: url('../shared/assets/fonts/ABCDiatype-Bold.woff') format('woff'),
    url('../shared/assets/fonts/ABCDiatype-Bold.woff2') format('woff2'),
    url('../shared/assets/fonts/ABCDiatype-Bold.otf') format('opentype');
}

html {
    background-color: #F2F0ED; /* silver-40 */
    font-size: 15px;
}
@media (min-width: 768px) { /* tailwind breakpoint 'md' */
    html {
        font-size: 16px;
    }
}
